import { useCallback } from 'react';
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function useStripeCustomer() {
  const fetchBillingPeriod = useCallback(async () => {
    try {
      const url = `${BASE_URL}/api/v1/stripe/billing-period`;

      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      const data = await resp.json();

      if (resp.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, []);

  const checkStripeCustomer = async () => {
    let result = { status: 200, data: null, error: null }; // default result

    try {
      const url = `${BASE_URL}/api/v1/auth/check-customer`;
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      result.status = resp.status;

      if (!resp.ok) {
        // Extract the response body to see what kind of error occurred
        const errorBody = await resp.json();

        if (result.status === 401) {
          if (errorBody.type === 'TokenExpiredError') {
            throw new Error(`Token has expired. Please log in again.`);
          }
          throw new Error(`HTTP Error! Status: ${resp.status}`);
        } else if (result.status === 404) {
          result.data = 'No profile found';
        } else {
          // For other non-2xx HTTP status codes:
          throw new Error(errorBody.message || 'Unknown error occurred');
        }
      } else {
        result.data = await resp.json();
      }
    } catch (e) {
      console.error('Failed authentication', e);
      throw e;
    }

    return result;
  };

  return { fetchBillingPeriod, checkStripeCustomer };
}
