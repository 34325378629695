import React, { useEffect, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuthContext } from '../../../context/AuthContext.js';

export default function PasswordInput() {
  const { password, setPassword, error, setError } = useAuthContext();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInputError = () => {
    switch (error) {
      case 'Incorrect username or password.':
        return true;
      case 'Passwords do not match':
        return true;
      default:
        return false;
    }
  };

  const getHelperText = () => {
    switch (error) {
      case 'Incorrect username or password.':
        return 'Incorrect username or password.';
      case 'Passwords do not match':
        return 'Passwords do not match';
      default:
        return '';
    }
  };

  return (
    <TextField
      fullWidth
      id="password-input"
      label="Password"
      variant="filled"
      type={showPassword ? 'text' : 'password'}
      autoComplete="current-password"
      required
      defaultValue={password}
      onChange={(e) => {
        setPassword(e.target.value);
      }}
      error={handleInputError()}
      helperText={getHelperText()}
      onInput={() => setError('')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      size="small"
      inputProps={{ 'data-testid': 'password-input' }}
    />
  );
}
