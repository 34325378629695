import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { usePosts } from '../../hooks/usePosts.js';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  ButtonGroup,
  useMediaQuery,
  useTheme,
  Checkbox,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { toast } from 'react-toastify';
import usePostStore from '../../stores/usePostStore.js';

export default function InventoryMgtForm({
  selectedButton,
  handleNewClick,
  mode,
  handleProductSubmit,
  handleProductChange,
  product,
  products,
  dateAsNumber,
  setProduct,
  files,
  setFiles,
  currentImage,
  setCurrentImage,
  handleFormClose,
  createGalleryPost,
  setCreateGalleryPost,
  formLoading,
  setOriginalThumbnail,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { restricted } = usePostStore();

  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const [showValidation, setShowValidation] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowValidation(true);

    const requiredFields = ['type', 'date', 'num_days', 'price', 'description'];
    if (product.type !== 'prep-other') {
      requiredFields.push('title', 'category');
    }

    if (product.type === 'prep-other') {
      product.title === 'Prep work/ other';
      product.price = (-product.price).toString();
    }

    const isValid = requiredFields.every((field) => product[field]);
    const hasImage = createGalleryPost ? files.length > 0 : currentImage;

    if (isValid && hasImage) {
      handleProductSubmit(e);
      setShowValidation(false);
    } else {
      const fieldLabels = {
        num_days: 'Number of Days',
        // Add any other special cases here
      };

      const missingFields = requiredFields.filter((field) => !product[field]);
      if (!hasImage) {
        missingFields.push('image');
      }

      const formattedFields = missingFields.map(
        (field) =>
          fieldLabels[field] ||
          field
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
      );

      toast.warn(`Required fields missing: ${formattedFields.join(', ')}`, {
        theme: 'dark',
        draggable: true,
        draggablePercent: 60,
      });
    }
  };

  const handleTabChange = (newTab) => {
    setShowValidation(false); // Reset validation when changing tabs
    setActiveTab(newTab);
  };

  useEffect(() => {
    if (mode === 'edit' && product.image_url) {
      setCurrentImage(product.image_url);
      setFiles([]);
    }
  }, [product.image_url, mode, setFiles, setCurrentImage]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const mappedFiles = acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      if (createGalleryPost) {
        setFiles((prevFiles) => [...prevFiles, ...mappedFiles]);
        setCurrentImage(mappedFiles);
      } else {
        setFiles(mappedFiles);
        setCurrentImage(mappedFiles[0].preview);
      }
    },
    [createGalleryPost, setFiles, setCurrentImage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    multiple: createGalleryPost,
    maxFiles: 10,
  });

  useEffect(() => {
    let newCategories = [
      'Beads',
      'Blunt tips',
      'Bubblers',
      'Collabs',
      'Cups',
      'Dry Pieces',
      'Goblets',
      'Iso stations',
      'Marbles',
      'Pendants',
      'Recyclers',
      'Rigs',
      'Slides',
      'Spinner caps',
      'Terp pearls',
      'Tubes',
      'Misc',
    ];
    setCategories(newCategories);
  }, [products]);

  const handleImageDelete = (index) => {
    if (createGalleryPost) {
      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    } else {
      setCurrentImage('');
    }
  };

  useEffect(() => {
    setOriginalThumbnail(product.image_url);
  }, [setOriginalThumbnail, product.image_url, files]);

  const renderThumbnails = () => (
    <div className="thumbnails-container">
      {createGalleryPost ? (
        files.map((file, index) => {
          return (
            <div key={file.name} className="thumbnail-wrapper">
              <img src={file.preview} alt={`Preview ${index + 1}`} className="thumbnail" />
              <button
                type="button"
                className="delete-button-form"
                onClick={() => handleImageDelete(index)}
              >
                X
              </button>
            </div>
          );
        })
      ) : (
        <div className="thumbnail-wrapper">
          {currentImage && (
            <>
              <img src={currentImage} alt="Current image" className="thumbnail" />
              <button
                type="button"
                className="delete-button-form"
                onClick={() => setCurrentImage('')}
              >
                X
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );

  const handleCheckboxChange = (event) => {
    setCreateGalleryPost(event.target.checked);
    setFiles([]);
  };

  // Define tabs and their content
  const tabs = [
    {
      label: 'Basic Info',
      content: (
        <div className="tab1-wrapper">
          <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
            <InputLabel id="product-type-label" sx={{ fontSize: '0.75rem' }}>
              Type
            </InputLabel>
            <Select
              labelId="product-type-label"
              name="type"
              value={product.type}
              onChange={handleProductChange}
              label="Type"
              required
              sx={{
                height: '45px',
                '& .MuiSelect-select:focus': {
                  backgroundColor: 'transparent',
                  height: 30,
                },
              }}
              error={showValidation && !product.type}
              helpertext={showValidation && !product.type ? 'Type is required' : ''}
            >
              <MenuItem value="auction">Auction</MenuItem>
              <MenuItem value="direct-sale">Direct Sale</MenuItem>
              <MenuItem value="inventory">Website</MenuItem>
              <MenuItem value="prep-other">Prep/ Other</MenuItem>
            </Select>
          </FormControl>

          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            error={showValidation && !product.date}
            helpertext={showValidation && !product.date ? 'Date is required' : ''}
          >
            <DatePicker
              label="Date"
              value={mode === 'edit' ? dateAsNumber : product.date ? new Date(product.date) : null}
              onChange={(newValue) => {
                const newDateValue = newValue ? newValue.getTime() : null;
                setProduct((prev) => ({ ...prev, date: newDateValue }));
              }}
              required
              sx={{ mb: 1 }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  sx: {
                    '& .MuiInputBase-root': {
                      height: 45,
                      fontSize: '0.875rem',
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>

          <TextField
            fullWidth
            label="Number of Days"
            required
            name="num_days"
            type="number"
            value={product.num_days || ''}
            onChange={handleProductChange}
            inputProps={{ min: '1', step: '1' }}
            sx={{
              mb: 1,
              '& .MuiInputBase-root': {
                height: 45,
                fontSize: '0.875rem',
                padding: '0 0 0 75%',
              },
            }}
            error={showValidation && !product.num_days}
            helpertext={showValidation && !product.num_days ? 'Number of days is required' : ''}
          />
        </div>
      ),
    },
    {
      label: 'Details',
      content: (
        <>
          {product.type !== 'prep-other' && (
            <>
              <TextField
                fullWidth
                label="Title"
                name="title"
                value={product.title || ''}
                onChange={handleProductChange}
                required
                sx={{
                  mb: 1,
                  '& .MuiInputBase-root': {
                    height: 45,
                    fontSize: '0.875rem',
                  },
                }}
                error={showValidation && !product.title}
                helpertext={showValidation && !product.title ? 'Title is required' : ''}
              />

              <FormControl fullWidth variant="outlined" sx={{ mb: 1 }}>
                <InputLabel sx={{ fontSize: '0.75rem' }}>Category</InputLabel>
                <Select
                  name="category"
                  value={product.category || ''}
                  onChange={handleProductChange}
                  label="Category"
                  required
                  error={showValidation && !product.category}
                  helpertext={showValidation && !product.category ? 'Category is required' : ''}
                >
                  {categories.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl component="fieldset" sx={{ width: '100%', mb: 1 }}>
                <FormLabel component="legend">Sold Status</FormLabel>
                <RadioGroup
                  aria-label="sold status"
                  name="sold"
                  value={product.sold ? 'true' : 'false'}
                  onChange={handleProductChange}
                  row
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: '1rem',
                    },
                  }}
                >
                  <FormControlLabel value="true" control={<Radio />} label="Sold" />
                  <FormControlLabel value="false" control={<Radio />} label="Available" />
                </RadioGroup>
              </FormControl>
            </>
          )}

          <TextField
            fullWidth
            label={product.type !== 'prep-other' ? 'Price' : 'Material Costs'}
            required
            name="price"
            type="number"
            value={product.price || ''}
            onChange={handleProductChange}
            inputProps={{ step: 1 }}
            sx={{
              mb: 1,
              '& .MuiInputBase-root': {
                height: 45,
                fontSize: '0.875rem',
                padding: '0 0 0 75%',
              },
            }}
            error={showValidation && !product.price}
            helpertext={showValidation && !product.price ? 'Price is required' : ''}
          />
        </>
      ),
    },
    {
      label: 'Images',
      content: (
        <>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Description"
            required
            name="description"
            value={product.description || ''}
            onChange={handleProductChange}
            sx={{ mb: 2 }}
            error={showValidation && !product.description}
            helpertext={showValidation && !product.description ? 'Description is required' : ''}
          />
          {!product.post_id && (
            <Box
              sx={{
                display: 'grid',
                gridTemplateRows: '1fr',
                gridTemplateColumns: '1fr 4fr',
                alignItems: 'center',
                border: '1px solid',
                borderColor: (theme) => theme.palette.primary.light,
                mb: 2,
              }}
            >
              <Checkbox
                checked={createGalleryPost}
                onChange={handleCheckboxChange}
                sx={{ gridColumn: '1' }}
              />
              <span>Create new gallery post</span>
            </Box>
          )}
          <Box
            {...getRootProps()}
            className="dropzone"
            sx={{
              borderColor: (theme) => theme.palette.primary.light,
              borderWidth: '1px',
              borderStyle: 'solid',
              borderRadius: '5px',
              padding: '10px',
              mb: 2,
              height: isMobile ? '45px' : '70px',
              '&:hover': {
                cursor: 'pointer',
                backgroundColor: (theme) => theme.palette.primary.main,
              },
            }}
          >
            <input {...getInputProps()} />
            <Typography>
              {isMobile
                ? 'Tap to select an image'
                : "Drag 'n' drop an image here, or click to select an image"}
            </Typography>
          </Box>
          {currentImage && <aside className="thumbs-container">{renderThumbnails()}</aside>}
        </>
      ),
    },
  ];

  return (
    <Box
      elevation={2}
      className="form-container-2"
      sx={{
        padding: 1,
        borderRadius: '5px',
        backgroundColor: 'background.paper',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <ButtonGroup>
          <Button
            variant={selectedButton === 'new' ? 'contained' : 'outlined'}
            onClick={handleNewClick}
            sx={{ display: selectedButton === 'new' ? 'none' : 'block' }}
          >
            New
          </Button>
        </ButtonGroup>

        {isMobile && (
          <Button
            onClick={handleFormClose}
            sx={{
              minWidth: 'auto',
              padding: 0,
            }}
          >
            <CloseIcon />
          </Button>
        )}
      </Box>

      <Typography variant="body1" sx={{ mb: 2 }}>
        {mode === 'new' ? 'Enter new product:' : 'Edit product:'}
      </Typography>
      <form onSubmit={handleSubmit} className="quota-tracking-form">
        {/* Tab Navigation */}
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            mb: 2,
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          {tabs.map((tab, index) => (
            <Button
              key={index}
              onClick={() => setActiveTab(index)}
              sx={{
                px: 3,
                py: 1,
                borderBottom: activeTab === index ? 2 : 0,
                borderColor: 'primary.main',
                color: activeTab === index ? 'primary.main' : 'text.primary',
                flex: isMobile ? '1' : 'initial',
              }}
            >
              {tab.label}
            </Button>
          ))}
        </Box>

        {/* Tab Content */}
        <Box sx={{ mb: 2 }}>{tabs[activeTab].content}</Box>

        {/* Navigation and Submit Buttons */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '40px',
          }}
        >
          {mode === 'new' && !formLoading && (
            <Button variant="outlined" onClick={handleNewClick}>
              Reset
            </Button>
          )}

          <Box sx={{ display: 'flex', gap: 1, marginLeft: 'auto' }}>
            {mode === 'new' && !formLoading && (
              <Button
                variant="outlined"
                onClick={() => handleTabChange(activeTab - 1)}
                disabled={activeTab === 0}
              >
                Previous
              </Button>
            )}

            {activeTab === tabs.length - 1 ? (
              <Button
                type="submit"
                variant="contained"
                disabled={formLoading || restricted}
                startIcon={<AddCircleIcon />}
              >
                {(() => {
                  if (formLoading) return 'Uploading';
                  if (restricted) return 'Tracking disabled';
                  return mode === 'new' ? 'Add' : 'Save Changes';
                })()}
              </Button>
            ) : (
              <Button
                type="button"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabChange(activeTab + 1);
                }}
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </Box>
  );
}
