import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext.js';
import { useStripeContext } from '../context/StripeContext.js';

export default function useAppBar({ setAnchorElNav, setAnchorElUser }) {
  const { customerId, setCustomerId } = useStripeContext();
  const {
    handleSignOut,
    setEmail,
    setPassword,
    setCPassword,
    email,
    setUser,
    setIsAuthenticated,
    user,
  } = useAuthContext();
  const navigate = useNavigate();

  const handleLogoNavigation = () => {
    navigate('/');
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (e) => {
    switch (e.target.textContent) {
      case 'Gallery':
        setAnchorElNav(null);
        navigate('/');
        break;
      case 'Sign Up':
        setAnchorElNav(null);
        navigate('/auth/sign-up');
        break;
      case 'Sign In':
        setAnchorElNav(null);
        navigate('/auth/sign-in');
        break;
      case 'About':
        setAnchorElNav(null);
        navigate('/about');
        break;
      case 'Contact':
        setAnchorElNav(null);
        navigate('/contact');
        break;
      default:
        setAnchorElNav(null);
        break;
    }
  };

  const handleCloseUserMenu = (e) => {
    switch (e.target.textContent) {
      case 'Logout':
        handleSignOut(email, setUser, setIsAuthenticated, setCustomerId);
        setEmail('');
        setPassword('');
        setCPassword('');
        setAnchorElUser(null);

        // Browser autofill interferes with state updates while navigating, this delay fixes it
        setTimeout(() => {
          navigate('/auth/sign-in');
        }, 500);
        return;

      case 'Subscription':
        if (customerId === null || customerId === undefined) {
          navigate('/subscription');
          setAnchorElUser(null);
        } else {
          navigate('/dashboard');
          setAnchorElUser(null);
        }
        break;
      case 'Dashboard':
        navigate('/dashboard');
        setAnchorElUser(null);
        break;
      case 'Profile':
        navigate(`/profile/${user}`);
        setAnchorElUser(null);
        break;
      default:
        break;
    }
    setAnchorElUser(null);
  };

  return {
    handleLogoNavigation,
    handleOpenNavMenu,
    handleOpenUserMenu,
    handleCloseNavMenu,
    handleCloseUserMenu,
  };
}
