// useInventory.js
import { useState, useEffect } from 'react';
import { fetchInventorySnapshots } from '../services/fetch-inventory.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext.js';
import { useStripeContext } from '../context/StripeContext.js';
import useSnapshotStore from '../stores/useSnapshotStore.js';

export function useInventory() {
  const navigate = useNavigate();
  const { isAuthenticated, error, user } = useAuthContext();
  const { customerId } = useStripeContext();

  // Get both state and actions from the store
  const { snapshots, inventoryLoading, setInventoryLoading, setSnapshots, fetchSnapshots } =
    useSnapshotStore();

  useEffect(() => {
    const getSnapshots = async () => {
      try {
        if (!user || !isAuthenticated || !customerId || error === '401') {
          return;
        }
        setInventoryLoading(true); // Use the store action
        const data = await fetchInventorySnapshots();
        if (data === null) {
          navigate('/auth/sign-in');
          return;
        }
        setSnapshots(data); // Use the store action
        setInventoryLoading(false); // Use the store action
      } catch (error) {
        console.error('Failed to fetch inventory snapshots:', error.message);
        toast.error(`Error fetching inventory: ${error}`, {
          theme: 'dark',
          draggable: true,
          draggablePercent: 60,
          toastId: 'useInventory-1',
          autoClose: false,
        });
        setInventoryLoading(false); // Don't forget to set false on error
      }
    };

    getSnapshots();
  }, [isAuthenticated, navigate, error, customerId, user]);

  return { snapshots, inventoryLoading };
}
