const BASE_URL = process.env.REACT_APP_BASE_URL;

// get all Orders from database and display on admin page
export async function fetchOrders() {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/orders`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    const data = await resp.json();

    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error fetching orders: ${data.message || `Status ${resp.status}`}`);
      throw new Error(`Error fetching orders: ${data.message || `Status ${resp.status}`}`);
    }

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in fetchOrders', error.message);
    throw error;
  }
}

export async function insertNewOrder(orderData) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderData,
      }),
      credentials: 'include',
    });

    const data = await resp.json();

    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error creating new order: ${data.message || `Status ${resp.status}`}`);
      throw new Error(`Error creating new order: ${data.message || `Status ${resp.status}`}`);
    }

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

export async function editOrder(orderId, orderData) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/orders/${orderId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderData,
      }),
      credentials: 'include',
    });

    const data = await resp.json();

    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error editing order: ${data.message || `Status ${resp.status}`}`);
      throw new Error(`Error editing order: ${data.message || `Status ${resp.status}`}`);
    }

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

export async function updateFulfillmentStatus(orderId, isFulfilled) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/orders/${orderId}/fulfillment`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        isFulfilled,
      }),
      credentials: 'include',
    });

    const data = await resp.json();

    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error editing fulfillment status: ${data.message || `Status ${resp.status}`}`);
      throw new Error(
        `Error editing fulfillment status: ${data.message || `Status ${resp.status}`}`
      );
    }

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

export const deleteOrder = async (orderId) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/orders/${orderId}`, {
      method: 'DELETE',
      credentials: 'include',
    });

    if (!response.ok) {
      if (response.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error deleting order: Status ${response.status}`);
      throw new Error(`Error deleting order:| Status ${response.status}`);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};
