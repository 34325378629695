import React from 'react';
import { Box, Button, Card, CardContent, Grid, Typography, useMediaQuery } from '@mui/material';
import '../../Subscription.css';
import useLoadingState from '../../../../context/LoadingContext.js';
import LandingPageInfo from './LandingPageInfo.js';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../context/AuthContext.js';
import PromotionalVideo from './PromotionalVideo.js';
import { useTheme } from '@emotion/react';
import { useSubscriptionStageManager } from '../../../../hooks/useSubscriptionStageManager.js';

export default function SubscriptionLandingPage() {
  const { setStage } = useSubscriptionStageManager();
  const { setPageLoading } = useLoadingState();
  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSignInToSubscribe = () => {
    navigate('/auth/sign-up');
  };

  const handleStageChange = () => {
    setStage(1);
    navigate('/subscription/form');
  };

  return (
    <Box sx={{ flexGrow: 1, p: isMobile ? 0 : 2, position: 'absolute', top: '70px' }}>
      <Typography variant="h4" textAlign={'left'} sx={{ borderBottom: '2px solid green' }}>
        Become a subscriber!
      </Typography>
      <Grid container spacing={4} alignItems="start" sx={{ padding: '20px' }}>
        {/* Text and Video Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            '&.MuiGrid-item': { paddingLeft: '15px' },
          }}
        >
          <PromotionalVideo />
          {/* Action Button */}
          <Grid item xs={12} sx={{ marginTop: '5%' }}>
            <Box textAlign="center">
              {isAuthenticated ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleStageChange}
                  sx={{ mb: 2 }}
                >
                  Purchase a Subscription
                </Button>
              ) : (
                <Button color="primary" variant="contained" onClick={handleSignInToSubscribe}>
                  Sign-up to Subscribe
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            overflow: 'hidden',
            '&.MuiGrid-item': { paddingLeft: '15px' },
          }}
        >
          <LandingPageInfo isMobile={isMobile} />
        </Grid>

        <Grid item xs={12}></Grid>
      </Grid>
    </Box>
  );
}
