import React, { useEffect } from 'react';
import { Box, TextField, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseIcon from '@mui/icons-material/Close';
import { usePosts } from '../../hooks/usePosts.js';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ToastContainer, toast } from 'react-toastify';
import usePostStore from '../../stores/usePostStore.js';

export default function OrderForm({
  orders,
  handleSubmit,
  selectedOrder,
  formMode,
  setFormMode,
  resetForm,
  orderDate,
  setOrderDate,
  clientName,
  setClientName,
  shipping,
  setShipping,
  items,
  setItems,
  handleFormClose,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { restricted } = usePostStore();

  const handleItemChange = (index, field, value) => {
    const newItems = items.map((item, i) => {
      if (index === i) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setItems(newItems);
  };

  const addItem = () => {
    setItems([...items, { itemName: '', quantity: '' }]);
  };

  const removeItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const getNewOrderNumber = (orders) => {
    const highestOrderNumber = orders.reduce((max, order) => {
      const currentOrderNumber = Number(order.order_number) || 0; // Ensure it's a number
      return currentOrderNumber > max ? currentOrderNumber : max;
    }, 0);
    return highestOrderNumber + 1;
  };

  const validateOrderItems = (items) => {
    return items.every(
      (item) =>
        item.itemName?.trim() &&
        !isNaN(item.quantity) &&
        item.quantity > 0 &&
        !isNaN(item.rate) &&
        item.rate >= 0 &&
        item.category?.trim()
      // Add description validation if it's required
    );
  };

  const onSubmit = (e) => {
    try {
      e.preventDefault();
      isMobile && handleFormClose();
      const newOrderNumber = getNewOrderNumber(orders);

      // Validate required fields first
      if (!orderDate || !clientName?.trim() || !items?.length) {
        throw new Error('Missing required order fields');
      }

      // Validate items array
      if (!validateOrderItems(items)) {
        throw new Error('Invalid or missing item details');
      }

      const formattedItems = items.map((item) => ({
        name: item.itemName,
        qty: parseInt(item.quantity, 10),
        rate: parseFloat(item.rate),
        category: item.category,
        description: item.description,
      }));

      // Validation for parsed numbers
      const hasInvalidNumbers = formattedItems.some((item) => isNaN(item.qty) || isNaN(item.rate));
      if (hasInvalidNumbers) {
        throw new Error('Please enter valid numbers for quantity and rate');
      }

      const orderData = {
        date: orderDate,
        client_name: clientName,
        items: JSON.stringify(formattedItems), // Convert array to JSON string
        shipping: parseFloat(shipping),
        order_number: formMode === 'new' ? newOrderNumber : selectedOrder.order_number,
      };

      handleSubmit(orderData, formMode);
    } catch (e) {
      if (e.message.includes('missing' || 'Missing') || e.message.includes('Please')) {
        toast.warn(`${e.message}. Please fill form out completely.`, {
          theme: 'dark',
          draggable: true,
          draggablePercent: 60,
          toastId: 'orderForm-2',
        });
      } else {
        toast.error(`Error posting new order: ${e}`, {
          theme: 'dark',
          draggable: true,
          draggablePercent: 60,
          toastId: 'orderForm-2',
        });
      }
    }
  };

  useEffect(() => {
    if (formMode === 'edit' && selectedOrder) {
      setOrderDate(new Date(selectedOrder.date));
      setClientName(selectedOrder.client_name);
      setShipping(selectedOrder.shipping || 0);
      setItems(
        selectedOrder.items.map((item) => ({
          itemName: item.name, // Adjust based on actual property names
          quantity: item.qty,
          rate: item.rate,
          category: item.category,
          description: item.description,
        }))
      );
    } else {
      setOrderDate(new Date());
      setClientName('');
      setShipping(0);
      setItems([{ itemName: '', quantity: '', rate: '', category: '', description: '' }]);
    }
  }, [selectedOrder, formMode, setOrderDate, setClientName, setShipping, setItems]);

  return (
    <Box
      sx={{
        padding: 1,
        border: '1px solid',
        borderRadius: '5px',
        backgroundColor: 'background.paper',
        borderColor: (theme) => theme.palette.primary.dark,
        transform: ' translate(0%, -3%)',
      }}
    >
      {isMobile && (
        <Button
          onClick={handleFormClose}
          sx={{
            position: 'absolute',
            right: 5,
            top: 5,
            minWidth: 'auto',
            padding: 0,
          }}
        >
          <CloseIcon />
        </Button>
      )}
      <form onSubmit={onSubmit}>
        <Typography variant="h6">
          {formMode === 'new' ? 'Enter New Order' : `Edit Order #${selectedOrder.order_number}`}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              fullWidth
              value={orderDate}
              onChange={setOrderDate}
              required
              sx={{ marginBottom: 1 }}
              // renderInput={(params) => <TextField {...params} fullWidth />}
              slotProps={{
                textField: {
                  // Pass all the TextField props here
                  fullWidth: true,
                },
              }}
            />
          </LocalizationProvider>
        </Box>
        <TextField
          fullWidth
          label="Client"
          value={clientName || ''}
          onChange={(e) => setClientName(e.target.value)}
          required
          inputProps={{ maxLength: 255 }}
          sx={{ margin: '0px 0px 10px 0px' }}
        />

        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'stretch' : 'center',
              margin: '0 5px',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 1 }}>
              <TextField
                label="Item"
                value={item.itemName || ''}
                onChange={(e) => handleItemChange(index, 'itemName', e.target.value)}
                required
                inputProps={{ maxLength: 255 }}
                sx={{ marginRight: 1, flex: 1 }}
              />
              <TextField
                label="Category"
                value={item.category || ''}
                onChange={(e) => handleItemChange(index, 'category', e.target.value)}
                required
                inputProps={{ maxLength: 255 }}
                sx={{ flex: 1 }}
              />
            </Box>
            <TextField
              label="Description"
              value={item.description || ''}
              onChange={(e) => handleItemChange(index, 'description', e.target.value)}
              required
              fullWidth
              inputProps={{ maxLength: 255 }}
              sx={{ marginBottom: 1 }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 1 }}>
              <TextField
                label="Qty"
                type="number"
                value={item.quantity || ''}
                onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                required
                sx={{ marginRight: 1, flex: 1 }}
                inputProps={{ min: 0, max: 1000000 }}
              />
              <TextField
                label="Rate"
                type="number"
                value={item.rate || ''}
                onChange={(e) => handleItemChange(index, 'rate', e.target.value)}
                required
                sx={{ flex: 1 }}
                inputProps={{ min: 0, max: 1000000 }}
              />
            </Box>
            <Button
              onClick={() => removeItem(index)}
              sx={{ alignSelf: isMobile ? 'flex-end' : 'center', marginBottom: 1, marginLeft: 1 }}
            >
              Remove
            </Button>
          </Box>
        ))}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button onClick={addItem} sx={{ marginBottom: 2 }}>
            Add Item
          </Button>
          <TextField
            label="Shipping"
            type="number"
            value={shipping}
            onChange={(e) => setShipping(e.target.value)}
            sx={{ marginBottom: 1, width: isMobile ? '100%:' : '20%' }}
          />

          {!restricted ? (
            <Button type="submit" variant="contained" startIcon={<AddCircleIcon />}>
              {formMode === 'new' ? 'Create New Order' : 'Save Changes'}
            </Button>
          ) : (
            <Button type="submit" variant="contained" disabled>
              New Orders disabled
            </Button>
          )}

          {formMode === 'edit' && (
            <Button
              onClick={() => {
                setFormMode('new');
                resetForm();
              }}
              sx={{ marginTop: 1 }}
            >
              Cancel
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
}
