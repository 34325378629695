import React from 'react';
import { Container, Link, Typography, List, ListItem, ListItemText, Box } from '@mui/material';

export default function LandingPageInfo({ isMobile }) {
  const features = [
    'Create your own gallery and profile page with the ability to create content.',
    'Keep track of post inventory valuations over time. Download CSV file from your dashboard for spreadsheets, etc. Track orders and print invoices.',
    'Set goals for monthly and daily quotas to manage your production.',
    'See how your business is doing at a glance with a color coded calendar. You can always review what you made, when, and for how much $ to stay consistent with your product.',
    'Monthly analysis with user data based graphs.',
    'Peace of mind that your data is encrypted with AES-256 encryption- no one is stealing your data. ',
  ];
  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: {
          xs: 2,
          sm: 3,
        },
        alignItems: 'flex-start',
        padding: '0px',
      }}
    >
      {/* Bulleted List of Features */}
      <Box
        sx={{
          borderBottom: '1px solid ',
          padding: '0px',
        }}
      >
        {' '}
        <Typography
          variant="h5"
          textAlign="left"
          sx={{ letterSpacing: '.1rem', marginBottom: '10px' }}
        >
          Our platform offers:
        </Typography>
        <Box
          sx={{
            height: isMobile ? '50vh' : 'content',
            overflowY: isMobile ? 'scroll' : '',
            backgroundColor: (theme) => theme.palette.primary.dark,
          }}
        >
          <List sx={{ listStyleType: 'disc', margin: '0 0 0 20px', padding: '10px' }}>
            {features.map((feature, index) => (
              <ListItem key={index} sx={{ display: 'list-item' }}>
                <Typography variant="body1" sx={{ letterSpacing: '.1rem' }}>
                  {feature}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>

      {/* First Paragraph */}
      <Typography variant="body1" textAlign="left" sx={{ letterSpacing: '.1rem' }}>
        Why subscribe? We designed this website to be a powerful tool for creators to help organize
        and run their businesses, so they can lighten the load of trying to keep track of everything
        so they can focus more on what matters: making art.
      </Typography>

      <Typography>
        Your subscription will be handled securely and directly through{' '}
        <Link
          href="https://stripe.com/"
          target="_blank"
          rel="noopener"
          sx={{ textDecoration: 'none' }}
        >
          Stripe
        </Link>
        .
      </Typography>
    </Container>
  );
}
