import { create } from 'zustand';
import userDefaultImage from './../assets/user.png';

const useProfileStore = create((set) => ({
  profilePicture: null,
  userSub: null, // Store the sub (user identifier)
  setProfilePicture: (picture, sub) =>
    set((state) => {
      if (state.userSub === sub) {
        // Check if the sub matches the current user's sub
        return { profilePicture: picture || userDefaultImage };
      }
      return state; // If not, return the current state unchanged
    }),
  setUserSub: (sub) => set({ userSub: sub }), // Setter for the user sub
  clearProfilePicture: () => set({ profilePicture: null }),
}));

export default useProfileStore;
