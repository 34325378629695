import { useEffect } from 'react';
import usePostStore from '../stores/usePostStore';
import { fetchPosts } from '../services/fetch-utils.js';
import { useAuthContext } from '../context/AuthContext.js';
import { useStripeContext } from '../context/StripeContext.js';
import { toast } from 'react-toastify';

export function usePosts() {
  const { setPosts, setLoading, setRestricted, setPostError } = usePostStore();
  const { user, isAuthenticated, email, handleSignOut, setError, setUser } = useAuthContext();
  const { customerId, setCustomerId } = useStripeContext();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading

      // Early return if required conditions are not met
      if (!user || !isAuthenticated || !customerId) {
        setLoading(false); // Stop loading on early exit
        return;
      }

      try {
        const data = await fetchPosts();

        if (data.code === 401) {
          setError('401');
          handleSignOut(email, setUser, setError, setCustomerId);
          setLoading(false); // Stop loading after unauthorized error
          return;
        }

        setPosts(data.posts || []);
        setRestricted(data.restricted || false);
        setLoading(false); // Stop loading on success
      } catch (error) {
        console.error('Error caught in usePosts hook:', error);

        setPostError(error.message);
        setLoading(false); // Stop loading on failure

        toast.error(`Error fetching posts: ${error.message}`, {
          theme: 'dark',
          toastId: 'usePosts-error',
          autoClose: false,
        });
      }
    };

    fetchData();
  }, [
    user,
    isAuthenticated,
    customerId,
    setPosts,
    setLoading,
    setRestricted,
    setPostError,
    email,
    handleSignOut,
    setError,
    setUser,
    setCustomerId,
  ]);
}
