import { useState } from 'react';
import { useEffect } from 'react';
import { fetchProducts } from '../services/fetch-products.js';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthContext } from '../context/AuthContext.js';
import { useStripeContext } from '../context/StripeContext.js';
export function useProducts() {
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [productError, setProductError] = useState(null);
  const navigate = useNavigate();

  const { isAuthenticated, error, user, email, setUser, setError, setCustomerId, handleSignOut } =
    useAuthContext();
  const { customerId } = useStripeContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user || !isAuthenticated || !customerId || error === '401') {
          return;
        }
        setLoadingProducts(true);
        const data = await fetchProducts();
        if (data === null) {
          // Handle unauthorized access silently
          setProductError('Unauthorized access - please log in.');
          navigate('/auth/sign-in');
          return;
        }
        if (data.code === 401) {
          setError('401');
          handleSignOut(email, setUser, setError, setCustomerId);
          return;
        }
        setProducts(data);
        setLoadingProducts(false);
      } catch (e) {
        if (e.response && e.response.status === 401) {
          // eslint-disable-next-line no-console
          console.error(e.response.data.message);
        }
        setProductError(e.message);
        // Trigger a Toastify error notification
        toast.error(`Error fetching products: ${e}`, {
          theme: 'dark',
          draggable: true,
          draggablePercent: 60,
          toastId: 'useProducts-1',
          autoClose: false,
        });
      }
    };
    fetchData();
  }, [
    customerId,
    error,
    isAuthenticated,
    navigate,
    user,
    email,
    handleSignOut,
    setCustomerId,
    setError,
    setUser,
  ]);

  return {
    products,
    setProducts,
    loadingProducts,
    setLoadingProducts,
    productError,
    setProductError,
  };
}
