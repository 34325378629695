import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext.js';
import { useStripeContext } from '../../context/StripeContext.js';

function StripeReturn() {
  const navigate = useNavigate();
  const { isAuthenticated, loadingAuth } = useAuthContext();
  const { customerId, loadingCustomerId } = useStripeContext();
  useEffect(() => {
    if (!loadingAuth) {
      if (isAuthenticated && customerId && !loadingCustomerId) {
        navigate('/dashboard');
      } else {
        navigate('/auth/sign-in');
      }
    }
  }, [loadingAuth, navigate, isAuthenticated, customerId, loadingCustomerId]);

  return null;
}

export default StripeReturn;
