import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

export function useSubscriptionStageManager() {
  const { result } = useParams();
  const getInitialStage = () => {
    if (result === 'success' || result === 'cancel' || result === 'dashboard') {
      return 2;
    }
    return 0;
  };

  const [stage, setStage] = useState(getInitialStage());

  // Load from localStorage on mount
  useEffect(() => {
    const storedState = localStorage.getItem('subscriptionStage');
    if (storedState) {
      try {
        const parsedState = JSON.parse(storedState);
        setStage(parsedState);
      } catch (error) {
        console.error('Error parsing stored state:', error);
        toast.error(`Error parsing stored state: ${error}`, {
          theme: 'dark',
          draggable: true,
          draggablePercent: 60,
          toastId: 'orders-2',
          autoClose: false,
        });
      }
    }
  }, []);

  // Save to localStorage when stage changes
  useEffect(() => {
    localStorage.setItem('subscriptionStage', JSON.stringify(stage));
  }, [stage]);

  // Handle URL result parameter
  useEffect(() => {
    switch (result) {
      case 'success':
        setStage(2);
        break;
      case 'cancel':
        setStage(2);
        break;
      case 'dashboard':
        setStage(2);
        break;
      default:
        setStage(0);
        break;
    }
  }, [result]);

  return { stage, setStage };
}
