const BASE_URL = process.env.REACT_APP_BASE_URL;

// fetch all products from database
export async function fetchProducts() {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/quota-tracking`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    // Check if the server responded with a non-200 status
    const data = await resp.json();
    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error fetching products: ${data.message || `Status ${resp.status}`}`);
      throw new Error(`Error fetching products: ${data.message || `Status ${resp.status} `}`);
    }

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching products:', error);
    throw error;
  }
}

// create new product in database
export async function postProducts({
  type,
  date,
  title,
  description,
  category,
  price,
  image_url,
  public_id,
  num_days,
  post_id,
  sold,
}) {
  date = new Date(date);
  const milliseconds = date.getTime();
  date = Number(milliseconds);

  try {
    const resp = await fetch(`${BASE_URL}/api/v1/quota-tracking`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type,
        date,
        title,
        description,
        category,
        price,
        image_url,
        public_id,
        num_days,
        post_id,
        sold,
      }),
      credentials: 'include',
    });

    // Check if the server responded with a non-200 status
    const msg = await resp.json();
    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(
        `Error creating new product: ${msg.message || msg.error || `Status ${resp.status}`}`
      );
      throw new Error(
        `Error creating new product: ${msg.message || msg.error || `Status ${resp.status} `}`
      );
    }

    return msg;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

// edit existing product in database
export async function editProducts({
  type,
  date,
  title,
  description,
  category,
  price,
  image_url,
  public_id,
  id,
  num_days,
  post_id,
  sold,
}) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/quota-tracking/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type,
        date,
        title,
        description,
        category,
        price,
        image_url,
        public_id,
        num_days,
        post_id,
        sold,
      }),
      credentials: 'include',
    });

    // Check if the server responded with a non-200 status
    const msg = await resp.json();
    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error uploading images: ${msg.message || `Status ${resp.status}`}`);
      throw new Error(
        `Error creating post: ${msg.message || `Status ${resp.status} - ${resp.statusText}`}`
      );
    }

    return msg;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}
export async function deleteProduct(id) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/quota-tracking/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    // Check if the server responded with a non-200 status
    const msg = resp.status;
    if (resp.status !== 204) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error deleting product: ${msg.message || `Status ${resp.status}`}`);
      throw new Error(`Error deleting product: ${msg.message || `Status ${resp.status} `}`);
    }

    return msg;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

//upload image[0] to Cloudinary
export async function uploadProductImage(image) {
  const formData = new FormData();
  formData.append('imageFiles', image[0]);

  try {
    const response = await fetch(`${BASE_URL}/api/v1/dashboard/upload`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });

    // Check if the server responded with a non-200 status
    const resultObject = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(
        `Error updating product image: ${resultObject.message || `Status ${response.status}`}`
      );
      if (resultObject.message.includes('File size too large')) {
        throw new Error('Your image is too large, please resize and try again.');
      } else {
        throw new Error(
          `Error updating product image: ${resultObject.message || `Status ${response.status} `}`
        );
      }
    }

    const result = resultObject.files;

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}
