import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuthContext } from '../../../context/AuthContext.js';

export default function ConfirmPassword() {
  const { cPassword, setCPassword, error, setError } = useAuthContext();
  const [showCPassword, setShowCPassword] = useState(false);
  const handleClickShowCPassword = () => setShowCPassword((show) => !show);
  const handleMouseDownCPassword = (event) => {
    event.preventDefault();
  };
  return (
    <TextField
      fullWidth
      id="confirm-password-input"
      label="Confirm Password"
      variant="filled"
      type={showCPassword ? 'text' : 'password'}
      autoComplete="off"
      required
      defaultValue={cPassword}
      onChange={(e) => setCPassword(e.target.value)}
      error={error === 'Passwords do not match' ? true : false}
      helperText={error === 'Passwords do not match' ? 'Passwords do not match' : ''}
      onInput={() => setError('')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle confirm password visibility"
              onClick={handleClickShowCPassword}
              onMouseDown={handleMouseDownCPassword}
            >
              {showCPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      size="small"
      inputProps={{ 'data-testid': 'confirm-password-input' }}
    />
  );
}
