import React from 'react';
import { TextField } from '@mui/material';
import { useAuthContext } from '../../../context/AuthContext.js';

export default function EmailInput() {
  const { email, setEmail, error, setError } = useAuthContext();
  return (
    <TextField
      id="email-input"
      label="Email"
      variant="filled"
      type="email"
      defaultValue={email}
      autoComplete="email"
      required
      fullWidth
      onChange={(e) => setEmail(e.target.value)}
      helperText={
        error === 'Incorrect username or password.' ? 'Incorrect username or password.' : ''
      }
      error={error === 'Incorrect username or password.' ? true : false}
      onInput={() => setError('')}
      size="small"
      inputProps={{ 'data-testid': 'email-input' }}
    />
  );
}
