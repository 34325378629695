import { Container, Typography } from '@mui/material';
import React from 'react';

export default function PurchaseFormInfo() {
  return (
    <Container maxWidth="sm">
      <Typography variant="h4">Purchase Subscription</Typography>
      <Typography variant="body1">
        Please confirm your information and update if necessary. You will be able to change your
        information in the Stripe Customer Portal anytime from your admin page once signed up for
        monthly or yearly subscription, as well as upgrade/ cancel your subscription.{' '}
      </Typography>
    </Container>
  );
}
