export async function fetchStripeSubscribe({
  priceId,
  firstName,
  lastName,
  billingEmail,
  setStage,
  customerId,
}) {
  try {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const resp = await fetch(`${BASE_URL}/api/v1/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId,
        firstName,
        lastName,
        billingEmail,
        customer: customerId,
      }),
      credentials: 'include',
    });

    const data = await resp.json();

    if (resp.ok) {
      window.location = data.url;
      setStage(2);
      return resp;
    } else {
      return Promise.reject(data);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw e;
  }
}

export async function fetchStripeCustomerPortal() {
  try {
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const resp = await fetch(`${BASE_URL}/api/v1/create-customer-portal-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    const data = await resp.json();

    if (resp.ok) {
      window.location = data.url;
      return resp;
    } else {
      return Promise.reject(data);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw e;
  }
}

export async function deleteOnCancel() {
  try {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const resp = await fetch(`${BASE_URL}/api/v1/stripe/cancel-deletion`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
      credentials: 'include',
    });

    const data = await resp.json();

    if (resp.ok) {
      return resp;
    }
    return Promise.reject(data);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw e;
  }
}
