import { TextField } from '@mui/material';
import React from 'react';

export default function BillingEmailInput({ billingEmail, setBillingEmail }) {
  return (
    <TextField
      id="billing-email-input"
      label="Billing Email"
      variant="filled"
      type="email"
      defaultValue={billingEmail}
      autoComplete="Email"
      required
      fullWidth
      onChange={(e) => setBillingEmail(e.target.value)}
      size="small"
      inputProps={{
        'data-testid': 'billing-email-input',
      }}
    />
  );
}
