/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Container } from '@mui/material';
import { useTheme } from '@emotion/react';
import './Subscription.css';

import useLoadingState from '../../context/LoadingContext.js';

import SubscriptionLandingPage from './SubscriptionPages/LandingPageComponents/SubscriptionLandingPage.js';
import SubscriptionFormPage from './SubscriptionPages/FormPageComponents/SubscriptionFormPage.js';
import Cancel from './SubscriptionPages/Cancel.js';
import Success from './SubscriptionPages/Success.js';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSubscriptionStageManager } from '../../hooks/useSubscriptionStageManager.js';
export default function Subscription() {
  const { stage, setStage } = useSubscriptionStageManager();
  const { pageLoading } = useLoadingState();
  const { result } = useParams();
  const theme = useTheme();

  // Retrieve the state from local storage and set it
  useEffect(() => {
    const storedState = localStorage.getItem('subscriptionStage');
    if (storedState) {
      try {
        const parsedState = JSON.parse(storedState);
        setStage(parsedState);
      } catch (error) {
        console.error('Error parsing stored state:', error);
        toast.error(`'Error parsing stored state: ${error}`, {
          theme: 'dark',
          draggable: true,
          draggablePercent: 60,
          toastId: 'orders-2',
          autoClose: false,
        });
      }
    }
  }, [setStage]);

  return (
    <>
      {pageLoading ? (
        <CircularProgress />
      ) : (
        <Box className={'subscribe-wrapper'}>
          <Container
            className="subscribe-container"
            maxWidth="xl"
            sx={{
              gap: theme.spacing(3),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {stage === 0 && <SubscriptionLandingPage />}
            {stage === 2 &&
              (result === 'success' ? <Success /> : result === 'cancel' ? <Cancel /> : null)}
          </Container>
        </Box>
      )}
    </>
  );
}
