import { useEffect } from 'react';
import { useQuery } from '../../context/QueryContext.js';
import { postAddImages, postPost } from '../../services/fetch-utils.js';
import PostForm from '../PostForm/PostForm.js';
import { useNavigate } from 'react-router-dom';
import { postProducts } from '../../services/fetch-products.js';
import { useProducts } from '../../hooks/useProducts.js';
import { useAuthContext } from '../../context/AuthContext.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function NewPost() {
  const { isAuthenticated } = useAuthContext();
  const { products, setProducts } = useProducts();
  const navigate = useNavigate();

  const { setNewPostCreated } = useQuery();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/auth/sign-in');
    }
  }, [isAuthenticated, navigate]);

  const handleAddProduct = async (productData) => {
    try {
      const response = await postProducts(productData);

      if (response) {
        setProducts((prevProducts) => {
          // Create a new array with the new product
          const updatedProducts = [...prevProducts, response];

          // Sort the array by the 'date' property
          updatedProducts.sort((a, b) => Number(b.date) - Number(a.date));

          return updatedProducts;
        });
      }
    } catch (error) {
      // handle any errors, maybe set an error state or show a toast
      // eslint-disable-next-line no-console
      console.error('Failed to add product:', error);
      toast.error(`Failed to add product: ${error}`, {
        theme: 'dark',
        draggable: true,
        draggablePercent: 60,
      });
    }
  };

  const submitHandler = async (newPost) => {
    try {
      const { title, description, image_url, category, price, public_id, num_imgs, sold } = newPost;

      // create new post with fetch call to db
      const post = await postPost(
        title,
        description,
        image_url,
        category,
        price,
        public_id,
        num_imgs,
        sold
      );

      // send image urls and public ids to db
      await postAddImages(newPost.additionalImages, post.id);

      // make fetch call to new controller for inserting new quota tracking entry
      const quotaEntry = {
        // customer_id: customerId,
        title,
        description,
        image_url,
        category,
        price,
        public_id,
        num_days: 1, // hard coded for now
        type: 'inventory',
        date: new Date().setHours(0, 0, 0, 0), // This sets the time to midnight
        sold,
        post_id: post.id,
      };

      handleAddProduct(quotaEntry);

      setNewPostCreated((prevState) => !prevState); // Toggle newPostCreated state

      navigate('/dashboard');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error creating new post: ', e.message);
      toast.error(`Error creating new post: ${e}`, {
        theme: 'dark',
        draggable: true,
        draggablePercent: 60,
      });
    }
  };
  return <PostForm submitHandler={submitHandler} />;
}
