import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { fetchQuotaGoals } from '../services/fetch-quota-goals.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext.js';
export function useQuota(customerId) {
  const [monthlyQuota, setMonthlyQuota] = useState([]);
  const [workdays, setWorkdays] = useState([]);
  const [quotaLoading, setQuotaLoading] = useState(true);
  const [quotaError, setQuotaError] = useState(null);
  const navigate = useNavigate();
  const { setError, isAuthenticated, error } = useAuthContext();
  useEffect(() => {
    const fetchData = async () => {
      try {
        // If there is no customer, return early to avoid the API call
        if (!isAuthenticated || !customerId || error === '401') {
          return;
        }
        setQuotaLoading(true);
        const data = await fetchQuotaGoals();

        if (data === null) {
          // Handle unauthorized access silently
          setQuotaError('Unauthorized access - please log in.');
          navigate('/auth/sign-in');
          return;
        }

        if (data.length > 0) {
          setWorkdays(data[0].work_days);
          setMonthlyQuota(data[0].monthly_quota);
        }

        setQuotaLoading(false);
      } catch (e) {
        if (e.response && e.response.status === 401) {
          // eslint-disable-next-line no-console
          console.error(e.response.data.message);
          setError('401');
        }

        // eslint-disable-next-line no-console
        console.error('useQuota fetch error', e.message);
        setQuotaError(e.message);
        toast.error(`Error fetching quota goals: ${e}`, {
          theme: 'dark',
          draggable: true,
          draggablePercent: 60,
          toastId: 'orders-1',
          autoClose: false,
        });
      }
    };
    fetchData();
  }, [customerId, navigate, error, isAuthenticated, setError]);

  const refreshQuotaData = useCallback(async () => {
    try {
      setQuotaLoading(true);

      const data = await fetchQuotaGoals();
      if (data === null) {
        // Handle unauthorized access silently
        setError('401');
        setQuotaError('Unauthorized access - please log in.');
        navigate('/auth/sign-in');
        return;
      }

      if (data.length > 0) {
        setWorkdays(data[0].work_days);
        setMonthlyQuota(data[0].monthly_quota);
      }

      setQuotaLoading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('useQuota refresh error', e.message);
      setQuotaError(e.message);
      // Trigger a Toastify error notification
      toast.error(`Error fetching quota goals: ${e}`, {
        theme: 'dark',
        draggable: true,
        draggablePercent: 60,
        toastId: 'useQuota-2',
        autoClose: false,
      });
      return;
    }
  }, []);

  useEffect(() => {
    refreshQuotaData();
  }, [refreshQuotaData]); // useEffect now depends on refreshQuotaData, which itself depends on customerId

  return {
    monthlyQuota,
    setMonthlyQuota,
    workdays,
    setWorkdays,
    quotaLoading,
    setQuotaLoading,
    quotaError,
    setQuotaError,
    refreshQuotaData, // export this function
  };
}
