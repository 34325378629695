import { Box, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteOnCancel } from '../../../services/stripe.js';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Cancel() {
  const navigate = useNavigate();

  const [countdown, setCountdown] = useState(5); // Start with 5 seconds

  // Deletion logic
  useEffect(() => {
    async function performDeletion() {
      try {
        await deleteOnCancel();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Deletion failed:', error.message);
        toast.error(`Error cancelling order: ${error.message}`, {
          theme: 'dark',
          draggable: true,
          draggablePercent: 60,
          toastId: 'orders-2',
          autoClose: false,
        });
      }
    }
    performDeletion();
  }, []);

  // Navigation logic
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    if (countdown === 0) {
      navigate('/');
    }

    return () => clearInterval(timer);
  }, [countdown, navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Container maxWidth="xs">
        <Typography variant="h1" color="error">
          Canceled Operation
        </Typography>
        <Typography variant="h6">
          please try again, or contact support if the problem persists.
        </Typography>
        <Typography
          onClick={() => {
            navigate('/');
          }}
          sx={{
            display: 'block',
            cursor: 'pointer',
            width: '50%',
            margin: '2rem auto',
          }}
        >
          {' '}
          This page will redirect in {countdown} second{countdown !== 1 ? 's' : ''}, click here if
          not.
        </Typography>
      </Container>
    </Box>
  );
}
