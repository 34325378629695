const BASE_URL = process.env.REACT_APP_BASE_URL;

// fetch all products from database
export async function fetchQuotaGoals() {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/goals?`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    // Check if the server responded with a non-200 status
    const data = await resp.json();
    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      // console.error(`Error fetching quota goals: ${data.message || `Status ${resp.status}`}`);
      throw new Error(`Error fetching quota goals: ${data.message || `Status ${resp.status} `}`);
    }

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in fetchQuotaGoals:', error.message);
    throw error;
  }
}

// edit existing product in database
export async function editQuotaGoals(quotaData) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/goals`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        quotaData,
      }),
      credentials: 'include',
    });

    // Check if the server responded with a non-200 status
    const msg = await resp.json();
    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error editing quota goals: ${msg.message || `Status ${resp.status}`}`);
      throw new Error(`Error editing quota goals: ${msg.message || `Status ${resp.status} `}`);
    }
    return msg;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}
