import { Box, Container, Typography } from '@mui/material';
import React from 'react';

export default function NotFound() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Container maxWidth="xs">
        <Typography variant="h1" color="error">
          404
        </Typography>
        <Typography variant="h6">Page Not Found - Hurting Scene</Typography>
      </Container>
    </Box>
  );
}
