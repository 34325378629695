import React from 'react';
import './Gallery.css';
import { useQuery } from '../../context/QueryContext.js';
import UserGallery from './UserGallery.js';

import FlamePipe from '../FlamePipe/FlamePipe.js';
import { useAuthContext } from '../../context/AuthContext.js';

export default function Gallery() {
  const { filteredData, fetchError, queryLoading, queryError } = useQuery();
  const { error } = useAuthContext(); // see below
  let toast = {};

  if (error) toast.message = error.message; // 9/19/23 this was needed  but was causing a bug, might still be an issue for some toasts but I think I got it sorted out
  if (fetchError) toast.message = fetchError;
  if (error) toast.message = error.message;

  return (
    <>
      {queryLoading ? (
        <>
          <div className="loading-detail-2">
            <FlamePipe />
          </div>
        </>
      ) : (
        <div style={{ position: 'relative', top: '85px' }}>
          <UserGallery data={filteredData} />
        </div>
      )}
    </>
  );
}
